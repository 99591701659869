import React, {Component} from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import {graphql } from 'gatsby';
import get from 'lodash/get'
import {documentToReactComponents} from '@contentful/rich-text-react-renderer';
import RegisterInterest from '../components/core/components/RegisterInterest';
import NewsLetterSignUp from '../components/core/components/NewsLetterSignUp';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import fonts from '../styles/fonts';
import colors from '../styles/colors';
import FullWidthImage from '../components/core/components/FullWidthImage';


const WhiteBackground = styled(Container)`
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 1440px;
  margin: auto;
`;

const TextContainer = styled.div`
    height: 100%;
    box-sizing: border-box;
    padding-top: 45px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    @media(min-width: 768px) {
      padding-top: 40px;
      padding-left: 90px;
      padding-right: 90px;
      padding-bottom: 100px;
    }
`;

const BlockTitle = styled.div`
    color: #555555;
    ${fonts.MontserratBold};
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
`;

const BorderLineDiv = styled.div`
    height: 4px;
    width: 117px;
    background: #ADE100;
    margin-top: 20px;
    margin-bottom: 25px;
    @media(min-width: 992px) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
`;

// const AboutUsDescription = styled.div`
//     color: #555555;
//    ${fonts.CormorantGaramondLight};
//    font-size: 50px;
//    line-height: 58px;
//    max-width: 625px;
//    padding-bottom: 65px;
// `;

const BlockIntro = styled.div`
  ${fonts.CormorantGaramondLight};
  color: ${colors.black};
  font-size: 30px;
  line-height: 38px;
  padding-left: 0px;
  // padding-bottom: 30px;

`;

const BlockDescription = styled.div`
    ${fonts.CormorantGaramondLight};
    color: ${colors.grey};
    font-size: 40px;
    line-height: 48px;
    padding-bottom: 30px;
    @media(min-width: 768px) {
        padding-bottom: 80px;
    }
    @media(min-width: 992px) {
        font-size: 50px;
        line-height: 58px;
   }
`;

const ContactDetails = styled.div`
   ${fonts.MontserratRegular};
   color: ${colors.black};
   font-size: 18px;
   line-height: 28px;
   margin-top: 12px;
   padding-bottom: 45px;
   
   a {
     color: ${colors.black};
   }
`;

const MapContainer = styled.div`
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
   @media(min-width: 768px) {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
   }
`;


class LocationPage extends Component {

  createMarkup = () => {
    return {__html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.892686068396!2d7.067918615496322!3d43.60877597912286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x11f7cd7c0ffe8a11!2sGreen%20Chilli%20Provisioning!5e0!3m2!1sen!2spl!4v1580857089340!5m2!1sen!2spl" width="100%" height="425" frameborder="0" style="border:0;" allowfullscreen=""></iframe>'};
  }
  render() {
    const path = this.props.location.pathname;
    const page = get(this, 'props.data.page.edges')[0].node;
    const registrationOptions = [{ region: 'Worldwide', description: 'Featuring 500+ fresh and pantry products, delivered by freight to land or shore worldwide.', link: '' }, { region: 'Mediterranean', description: 'Featuring 500+ fresh and pantry products, delivered by road to Mediterranean regions.', link: '' }];
    const {createMarkup} = this;
    return (
      <Layout>
        <SEO title={page.title}
             description={page.metaDescription}
             path={path}/>
        <WhiteBackground>
          <FullWidthImage img={page.heroImage.fluid} />
          <Container>
            <Row>
              <Col lg={8}>
                <TextContainer padding>
                  <BlockTitle>{page.title}</BlockTitle>
                  <BorderLineDiv />
                  <BlockDescription> {page.intro}</BlockDescription>
                  <BlockIntro>{documentToReactComponents(page.content.json)}</BlockIntro>
                </TextContainer>
              </Col>
              <Col lg={4}>
                <TextContainer padding>
                  <BlockTitle>France</BlockTitle>
                  <BorderLineDiv />
                  <ContactDetails>
                    Contact: <br/>
                    {/* {page.address.lon}<br/>
                    {page.address.lat}<br/><br/> */}
                    {/* {page.blocks.map((contact, index) =>
                      <div key={index}>
                        {contact.phone}<br/>
                        {contact.email}<br/> <br/>
                        {contact.address}<br/>
                      </div>
                    )} */}
                    +33 4 83 88 33 70<br />
                    +33 6 42 64 08 77<br/>
                    7 days per week<br/>
                    <a href="mailto:provisions@greenchilli.eu">provisions@greenchilli.eu</a><br/><br/>415 rue de Goa<br/>06600 Antibes,<br/>France
                  </ContactDetails>
                </TextContainer>
              </Col>
            </Row>
          </Container>
          <Container>
            <MapContainer>
              <div dangerouslySetInnerHTML={createMarkup()} />
            </MapContainer>
          </Container>
          <RegisterInterest registrationOptions={registrationOptions}/>
          <NewsLetterSignUp />
        </WhiteBackground>
      </Layout>
    )
  }
}

export default LocationPage;

export const pageQuery = graphql`
  query LocationPageQuery {
    page: allContentfulLocation {
      edges {
        node {
          id
          slug
          title
          metaDescription
          intro
          content {
            json
          }
          address {
            lon
            lat
          }
          heroImage: heroImage {
            fluid(
              maxWidth: 1920
              quality: 70
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          blocks {
            __typename
            ... on Node {
              ... on ContentfulBlockContact {
                id
                title
                phone
                email
                address
              }
            }
          }
        }
      }
    }
  }
`;
